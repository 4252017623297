body {
    margin: 0;
}

#root {
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    margin: 0px;
    margin-bottom: 5px !important;
}

.main {
    max-width: 1200px;
}

Header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    align-items: center;
}

.ant-alert {
    padding: 10px;
}

.ant-menu-vertical {
    border-right: none !important;
}

.portfolio .ant-statistic-title {
    white-space: nowrap;
}

.portfolio .ant-statistic-content-value {
    white-space: nowrap;
}

.portfolio-stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 3px;
    padding-right: 3px;
}

.portfolio-stats .ant-card {
    white-space: nowrap;
    display: inline-block;
    margin-top: 3px;
    margin-right: 3px;
}

.portfolio-stats .ant-card-body {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 0px;
}

.portfolio-stats .ant-statistic-title {
    margin-bottom: 0px;
}

h1 {
    font-size: 1.6em !important;
}

h2 {
    font-size: 1.4em !important;
}

.table-fillheight {
    position: absolute;
    top: 0px;
    bottom: 0px;

    .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            .ant-table {
                flex: auto;
                overflow: hidden;

                .ant-table-container {
                    height: 100%;
                    display: flex;
                    flex-flow: column nowrap;

                    .ant-table-header {
                        flex: none;
                    }

                    .ant-table-body {
                        flex: auto;
                        overflow: scroll;
                    }
                }
            }

            .ant-table-pagination {
                flex: none;
            }
        }
    }
}

.full-height-table-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;

    padding-bottom: 0px;
    .title {
        flex: 0;
    }

    .full-height-table {
        display: flex;
        position: relative;
        flex: 1;
    }
}

.mts-question-popover {
}

.ant-popover.guide {
    .ant-popover-arrow {
    }

    .ant-typography {
        margin-bottom: 0px;
    }
    .footer {
        margin-top: 10px;
    }
}

.ant-descriptions-item-label {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    text-align: right !important;
}

.ant-descriptions-item-content {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
}
